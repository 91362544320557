

















































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class HomeBanner extends Vue {
  @Prop(Boolean) isLargeBanner!: boolean
  @Prop(String) bannerHeader!: string
  @Prop(String) bannerSubHeader!: string
  @Prop(String) bannerImageUrl!: string
  @Prop(String) bannerLocation!: string

  handleCreatePodcast() {
    this.$store.commit('setYoutubeModal', 'PODCAST')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  handleUploadAudio() {
    this.$store.commit('setYoutubeModal', 'AUDIO')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  createNewTag() {
    this.$store.dispatch('showTagEdit', null)
  }
}
