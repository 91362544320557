

























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import AllPodcastsPage from '@/components/Podcast/AllPodcastsPage.vue'
import AllAudioPage from '@/components/Audio/AllAudioPage.vue'
import banner_podcast_image_svg from '@/assets/podcast_image_svg.svg'
import HomeBanner from '@/components/Home/HomeBanner.vue'
import { useFetchRssFeed } from '@/hooks/rssFeeds'
import { useGetAllTracks } from '@/hooks/audio'
import { computed, reactive, SetupContext } from '@vue/composition-api'
import { NETWORKROLES } from '@/constants/roles'
import { useAddNetworkUser, useGetNetworkUsers } from '@/hooks/network'
import { SUPPORT_VIDEO_MAIL } from '@/constants/env'
import AIBlog from '@/components/AI/AIBlog.vue'
import { useGetCustomTemplateStyles } from '@/hooks/subtitle'
import AIBlogDraft from '@/components/AI/AIBlogDraft.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AllPodcastsPage,
    AllAudioPage,
    HomeBanner,
    AIBlog,
    AIBlogDraft,
  },
  setup(_, context: SetupContext) {
    useGetCustomTemplateStyles()
    const allTrackParams: any = reactive({
      paginate: true,
      limit: 10,
      offset: 0,
      category: 'TRACK',
      fromYoutube: true,
    })
    const { data: rssFeedsData, isFetching: rssFeedLoading } = useFetchRssFeed({}, true, (data: any) => {
      context.root.$store.commit('setRssFeeds', data.data)
      context.root.$store.commit('setRssFeedsCount', data.count)
      context.root.$store.commit('setRssFeedsOffset', data.count)
      data?.data.map((rssFeed: any) =>
        context.root.$store.commit('addRssFeed', {
          rssFeed,
          rssFeedItems: [],
        })
      )
      context.root.$store.commit('setRssFeedUids')
    })

    const { data: allAudioTracksData, isLoading: allAudioTracksLoading } = useGetAllTracks(allTrackParams)

    // Add Support User in the network code

    const isEnabled = computed(
      () =>
        [NETWORKROLES.owner, NETWORKROLES.admin].includes(context.root.$store.getters?.currentNetwork?.role?.name) ||
        context.root.$permissions.isManageNetworkBillingAllowed()
    )

    const { mutate: addNetworkUsers } = useAddNetworkUser({ email: SUPPORT_VIDEO_MAIL, role: NETWORKROLES.admin })
    useGetNetworkUsers({ enabled: isEnabled }, async (data: any) => {
      context.root.$store.commit('setUsers', data)
      const isSupportExist = data.data?.network_users.filter(
        (obj: any) => obj.role.name === NETWORKROLES.admin && obj.user.email === SUPPORT_VIDEO_MAIL
      )
      if (
        isSupportExist.length === 0 &&
        context.root.$store.getters.email !== SUPPORT_VIDEO_MAIL &&
        !window.location.origin.includes('localhost')
      ) {
        addNetworkUsers()
      }
    })

    return {
      rssFeedsData,
      rssFeedLoading,
      allAudioTracksData,
      allAudioTracksLoading,
    }
  },
})
export default class ViewHome extends Vue {
  @Getter networkType!: any
  @Getter selectedProduct!: string

  @Action setSelectedRssFeedUid!: any
  selectedCollectionType: string = 'ALL_PODCASTS' // ALL_PODCASTS or ALL_AUDIOS
  loadingAudio: boolean = false
  totalTracks: number = 0

  bannerHeader: string = 'Import a new podcast'
  bannerSubHeader: string =
    'Manage and deliver unlimited podcast shows and episodes. Publish your episodes with interactive tags to youtube, and unlock wider audience and opportunities!'
  bannerImageUrl: string = banner_podcast_image_svg

  rssFeedsData!: any
  rssFeedLoading!: boolean
  allAudioTracksData!: any
  allAudioTracksLoading!: boolean

  selectedRssFeed!: any

  @Watch('rssFeedsData')
  watchRssFeedData() {
    this.handleRoutes()
  }

  handleRoutes() {
    const channel = this.$route.params.channel
    const uid = this.$route.params.id

    if (channel === 'podcasts' && (!uid || uid === 'undefined')) {
      this.selectedRssFeed = this.rssFeedsData.data[0]?.uid
      this.setSelectedRssFeedUid(this.selectedRssFeed)
      this.$router.push(`/home/podcasts/${this.selectedRssFeed}`)
      this.selectedCollectionType = 'ALL_PODCASTS'
    } else if (channel === 'podcasts' && uid) {
      this.selectedRssFeed = uid
      this.setSelectedRssFeedUid(uid)
      this.selectedCollectionType = 'ALL_PODCASTS'
    } else if (channel === 'all') {
      this.selectedCollectionType = 'ALL_AUDIOS'
      this.selectedRssFeed = undefined
    } else if (channel === 'blog') {
      this.selectedCollectionType = 'BLOG_TO_VIDEO'
      this.selectedRssFeed = undefined
    } else if (channel === 'draft') {
      this.selectedCollectionType = 'BLOG_DRAFT'
      this.selectedRssFeed = undefined
    }
    // @ts-ignore
    if (this.selectedRssFeed) this.$refs?.allPodcastPage.selectRssFeedUid(this.selectedRssFeed)
  }

  created() {
    const channel = this.$route.params.channel
    sessionStorage.removeItem('upload')
    sessionStorage.removeItem('feedUrl')
    sessionStorage.removeItem('feedUid')
    this.$store.commit('resetYoutubeSetting')
    this.$store.commit('resetPodcastSetting')
    this.$store.commit('setYTModalId', null)
    this.$store.commit('clearYoutubeState')

    const isBlogToVideo = ['VIDEO_FREE', 'VIDEO_BASIC', 'VIDEO_PRO'].includes(this.networkType)
    isBlogToVideo &&
      this.selectedProduct === 'BLOG' &&
      (this.selectedCollectionType = this.selectedCollectionType === 'BLOG_DRAFT' ? 'BLOG_DRAFT' : 'BLOG_TO_VIDEO')
    this.handleRoutes()

    channel === 'all' && (this.selectedCollectionType = 'ALL_AUDIOS')

    this.$watch(
      () => this.$route,
      () => {
        this.handleRoutes()
      }
    )
  }

  get selectedPodcast() {
    const uid = this.$route.params.id
    return uid
      ? this.rssFeedsData?.rssFeedByUid[this.selectedRssFeed] || this.rssFeedsData?.data[0]
      : this.rssFeedsData?.data[0]
  }

  get rssFeedsCount() {
    return this.rssFeedsData?.count
  }
  get allAudioTracksCount() {
    return this.allAudioTracksData?.count
  }

  @Watch('selectedCollectionType')
  watchCollectionTypeChange() {
    const uid = this.$route.params.id
    console.log(this.selectedCollectionType)

    !uid &&
      this.selectedCollectionType === 'ALL_PODCASTS' &&
      this.$router.push(`/home/podcasts/${this.rssFeedsData.data[0]?.uid}`)
    this.selectedCollectionType === 'BLOG_TO_VIDEO' && this.$router.push(`/home/blog`)
    this.selectedCollectionType === 'ALL_AUDIOS' && this.$router.push(`/home/all`)
    this.selectedCollectionType === 'BLOG_DRAFT' && this.$router.push(`/home/draft`)
  }

  selectRss(feedUid: string) {
    this.selectedRssFeed = feedUid
    this.setSelectedRssFeedUid(feedUid)
    this.$router.push(`/home/podcasts/${feedUid}`)
  }

  handleCreateNewPodcast() {
    this.$store.commit('setYoutubeModal', 'PODCAST')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  handleUploadAudio() {
    this.$store.commit('setYoutubeModal', 'AUDIO')
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }
}
