























































import { Component, Vue } from 'vue-property-decorator'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {},
})
export default class AIBlog extends Vue {
  handleMethod(method: string) {
    // Method can be Blog URL or TEXT
    mixpanel.track(method)
    webengage?.track('blog-option', { option: method })
    this.$gtag.event('blog-option', { option: method })
    if (localStorage.getItem('blogId')) {
      this.$router.push(`/home/blog/${localStorage.getItem('blogId')}?method=${method}`)
    } else this.$router.push(`/home/blog/new?method=${method}`)
  }
  handleVideo() {
    this.$router.push(`/home/podcasts/undefined`)
  }
}
