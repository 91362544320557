var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex bg-adori-light-gray br2 justify-between items-center light-gray ba b--adori-gray",
        class: _vm.isLargeBanner
          ? "full-height ph4"
          : "overview-banner pa5 mb3",
      },
      [
        _c(
          "div",
          {
            staticClass: "banner-left flex w-50 items-center",
            class: _vm.isLargeBanner ? "" : "pr4",
          },
          [_c("img", { attrs: { src: _vm.bannerImageUrl, alt: "" } })]
        ),
        _c("div", { staticClass: "w-50 flex" }, [
          _c("div", { staticClass: "banner-right" }, [
            _c("h1", { staticClass: "mt0" }, [
              _vm._v(_vm._s(_vm.bannerHeader)),
            ]),
            _c("p", {
              staticClass: "lh-copy silver",
              domProps: { innerHTML: _vm._s(_vm.bannerSubHeader) },
            }),
            _vm.bannerLocation === "ALL_AUDIOS"
              ? _c(
                  "div",
                  { staticClass: "flex justify-between" },
                  [
                    _vm.$permissions.isCreateEpisodeAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2",
                          attrs: {
                            text: "Upload a new track",
                            onClick: _vm.handleUploadAudio,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm.bannerLocation === "ALL_TAGS"
              ? _c(
                  "div",
                  [
                    _vm.$permissions.isCreateTagShowAllowed("")
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2",
                          attrs: {
                            text: "Create a new tag",
                            onClick: _vm.createNewTag,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "flex justify-start" },
                  [
                    _vm.$permissions.isCreatePodcastAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 ph4",
                          attrs: {
                            text: "Import a new podcast",
                            onClick: _vm.handleCreatePodcast,
                          },
                        })
                      : _vm._e(),
                    _vm.$permissions.isEditEpisodeAllowed()
                      ? _c("BaseButtonBorder", {
                          staticClass: "add-podcast b-adori-red",
                          attrs: {
                            text: "Upload an audio ",
                            onClick: _vm.handleUploadAudio,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }